import "src/app/professionalReferences/styles.scss";
import { Share } from "@capacitor/share";
import { isPlatform } from "@ionic/core";
import {
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonPage,
  IonRow,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { ProfessionalReferenceStatus } from "@src/app/professionalReferences/constants";
import { useCheckProfessionalReferenceLDFlag } from "@src/app/professionalReferences/hook/useCheckProfessionalReferenceLDFlag";
import { useFetchProfessionalReferences } from "@src/app/professionalReferences/hook/useFetchProfessionalReferences";
import { ProfessionalReferenceCard } from "@src/app/professionalReferences/referenceCard";
import { ReferenceErrorBanner } from "@src/app/professionalReferences/referenceErrorBanner";
import { ReferenceForm } from "@src/app/professionalReferences/referenceForm";
import { ReferenceHeaderBanner } from "@src/app/professionalReferences/referenceHeaderBanner";
import { RouterPath } from "@src/app/routing/constant";
import { TabRouterPathInfo } from "@src/app/routing/constant/tabRoute";
import { deprecatedDoNotUseLogError, logEvent } from "@src/appV2/lib/analytics";
import { BackButtonLink } from "@src/appV2/lib/AppBarHeader";
import { USER_EVENTS } from "@src/constants";
import {
  IProfessionalReference,
  IProfessionalReferencesProps,
  ISaveProfessionalReferenceResponse,
} from "@src/lib/interface/src/lib/professionalReference";
import { FC, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { ReferenceInfoCard } from "./referenceInfoCard";
import { AppV2AccountRoutes } from "../routing/constant/appV2AccountRoutes";

const ProfessionalReferences: FC<IProfessionalReferencesProps> = ({
  agent,
}: IProfessionalReferencesProps) => {
  const { isProfessionalReferencesEnabled } = useCheckProfessionalReferenceLDFlag();

  const { fetchReferences, professionalReferences } = useFetchProfessionalReferences();

  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const [showErrorBanner, setShowErrorBanner] = useState<boolean>(false);
  const [rejectedReferenceCount, setRejectedReferenceCount] = useState<number>(0);
  const [formKeys, setFormKeys] = useState<string[]>([]);
  const [focusOnFirstField, setFocusOnFirstField] = useState<boolean>(true);

  const history = useHistory();

  const backButtonRoute = useCallback((): string => {
    if (agent && !agent.onboardingFlags?.isSignupCompleted) {
      return RouterPath.ONBOARDING_UPLOAD_DOCUMENTS;
    }
    return AppV2AccountRoutes.DOCUMENTS;
  }, [agent]);

  useEffect(() => {
    if (!isProfessionalReferencesEnabled) {
      history.replace(backButtonRoute());
    }
  }, [isProfessionalReferencesEnabled, backButtonRoute, history]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await fetchReferences();
      setIsLoading(false);
    })();
  }, [fetchReferences]);

  useEffect(() => {
    if (professionalReferences && !professionalReferences?.completed) {
      const { minVerifiedReferenceRequired, references } = professionalReferences;
      const rejectedReferenceCount = references.filter(
        ({ status }) => status === ProfessionalReferenceStatus.DECLINED
      ).length;
      const pendingReferenceCount = references.filter(
        ({ status }) => status === ProfessionalReferenceStatus.REQUESTED
      ).length;
      const verifiedReferenceCount = references.filter(
        ({ status }) => status === ProfessionalReferenceStatus.VERIFIED
      ).length;
      setRejectedReferenceCount(rejectedReferenceCount);

      const hasRejectedMoreThanPending = rejectedReferenceCount > pendingReferenceCount;

      const verifiedLessThanRequired = verifiedReferenceCount < minVerifiedReferenceRequired;

      const notEnoughPendingOrVerified =
        pendingReferenceCount + verifiedReferenceCount < minVerifiedReferenceRequired;

      const shouldShowErrorBanner =
        (hasRejectedMoreThanPending && verifiedLessThanRequired) ||
        (rejectedReferenceCount > 0 && notEnoughPendingOrVerified);

      setShowErrorBanner(shouldShowErrorBanner);

      const hasEnoughReferences = references.length >= minVerifiedReferenceRequired;

      if (
        (hasEnoughReferences && hasRejectedMoreThanPending) ||
        (rejectedReferenceCount && notEnoughPendingOrVerified)
      ) {
        setFormKeys([crypto.randomUUID()]);
        return;
      }
      if (references.length < minVerifiedReferenceRequired) {
        setFormKeys(
          [
            ...Array(
              professionalReferences.minVerifiedReferenceRequired -
                professionalReferences.references.length
            ),
            /**
             * FIXME - Replace `window.randomUUID` with package import `uuid`
             * https://linear.app/clipboardhealth/issue/FEF-155/remove-windowcrypto-and-directly-use-uuid-package
             */
          ].map(() => crypto.randomUUID())
        );
        return;
      } else {
        setFormKeys([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [professionalReferences?.references.length]);

  const addMoreReferences = () => {
    setFocusOnFirstField(true);
    setFormKeys((prevKeys) => [...prevKeys, crypto.randomUUID()]);
  };

  const postSubmitFormAction = useCallback(
    async (response: ISaveProfessionalReferenceResponse | null) => {
      try {
        setFocusOnFirstField(false);
        if (response !== null) {
          const isCapacitor = isPlatform(window, "capacitor");
          const data = {
            title: "Professional Reference Request",
            text: `${response.inviteMessage?.messageText} ${response.inviteMessage?.webFormLink}`,
          };
          if (isCapacitor) {
            const shareResult = await Share.share(data);
            logEvent(USER_EVENTS.REFERENCE_APP_SHARE_ICON_TAPPED, {
              reference_id: response?.data?._id,
              reference_number: professionalReferences?.references?.length,
              app_name: shareResult?.activityType,
            });
          } else {
            await window?.navigator?.share(data);
          }
        }
      } catch (e) {
        deprecatedDoNotUseLogError({
          metadata: e,
          message: "Unable to share Reference message",
        });
      }
    },
    [professionalReferences]
  );

  const getReferenceCards = useCallback(() => {
    if (professionalReferences?.references.length) {
      return professionalReferences.references.map(
        (reference: IProfessionalReference, index: number) => {
          return (
            <ProfessionalReferenceCard
              completed={professionalReferences?.completed}
              professionalReference={reference}
              count={index + 1}
              key={reference._id}
            />
          );
        }
      );
    }
    return null;
  }, [professionalReferences]);

  const getReferenceForms = useCallback(() => {
    if (!professionalReferences) {
      return null;
    }
    const forms: JSX.Element[] = [];
    for (let referenceFormCount = 1; referenceFormCount <= formKeys.length; referenceFormCount++) {
      const firstForm = referenceFormCount === 1;
      forms.push(
        <ReferenceForm
          key={`reference-form-${formKeys[referenceFormCount - 1]}`}
          count={referenceFormCount + professionalReferences.references.length}
          firstForm={firstForm}
          focusOnFirstField={focusOnFirstField && firstForm}
          postSubmitFormAction={postSubmitFormAction}
          fetchReferences={fetchReferences}
          showErrorBanner={firstForm && showErrorBanner}
          rejectedReferenceCount={rejectedReferenceCount}
          professionalReferencesCount={professionalReferences.references.length}
          isScrollingToFirstFormEffectEnabled={professionalReferences.references.length > 0}
        />
      );
    }
    return forms;
  }, [
    professionalReferences,
    formKeys,
    postSubmitFormAction,
    fetchReferences,
    showErrorBanner,
    rejectedReferenceCount,
    focusOnFirstField,
  ]);

  return (
    <IonPage style={{ paddingBottom: "30px" }}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <BackButtonLink defaultBackTo={backButtonRoute()} />
          </IonButtons>
          <IonTitle className="urgent-shifts__header-title">
            {TabRouterPathInfo.PROFESSIONAL_REFERENCE.name}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {isLoading && (
          <IonRow style={{ height: "100%" }}>
            <IonCol
              sizeXs="12"
              style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flex: 1,
              }}
            >
              <IonSpinner class="ion-margin-start" data-testid="loading-indicator" />
            </IonCol>
          </IonRow>
        )}
        {!isLoading && (
          <div className="professional-reference-ion-content">
            {!showErrorBanner && (
              <ReferenceHeaderBanner professionalReferences={professionalReferences} />
            )}
            {showErrorBanner && formKeys.length === 0 && (
              <ReferenceErrorBanner
                showErrorBanner={showErrorBanner}
                rejectedReferenceCount={rejectedReferenceCount}
              />
            )}
            {getReferenceCards()}
            {getReferenceForms()}
          </div>
        )}
      </IonContent>
      {!isLoading &&
        (professionalReferences?.references.length ?? 0) > 0 &&
        !professionalReferences?.completed &&
        !showErrorBanner && (
          <ReferenceInfoCard
            minVerifiedReferenceRequired={
              professionalReferences?.minVerifiedReferenceRequired as number
            }
            onAddReference={addMoreReferences}
          />
        )}
    </IonPage>
  );
};

export default ProfessionalReferences;
